/* eslint-disable @wordpress/dependency-group */
export function createBubbles(parentEl, siteContainer) {
	const range = [...Array(13).keys()].slice(1);
	range.forEach((index) => {
		const bubble = document.createElement('div');
		bubble.className = 'bubble';
		bubble.id = `bubble${index}`;
		bubble.style.opacity = 0;
		parentEl.insertBefore(bubble, siteContainer);
	});
}

export function createMenuBubble(parentEl, siteContainer) {
	const menuBubble = document.createElement('div');
	menuBubble.className = 'menu-bubble';
	menuBubble.setAttribute('rel', 'menu-bubble');
	// menuBubble.style.opacity = 0;
	parentEl.insertBefore(menuBubble, siteContainer);
}
