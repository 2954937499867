/* eslint-disable @wordpress/dependency-group */
import ScrollReveal from 'scrollreveal';
import $ from 'jquery';
import { createBubbles, createMenuBubble } from './create-bubbles';
import createButtonUp from './button-up';

const delay = 500;
const reset = false;
const distance = '50px';
const origin = 'bottom';
const siteContainer = document.querySelector('.site-container');
const menuContainer = document.querySelector('.menu-primary');
const parentEl = siteContainer.parentNode;
const menuParentEl = menuContainer.parentNode;

// const mediaQuery = window.matchMedia('(min-width: 1024px)');
// if (mediaQuery.matches) {
// 	delay = 500;
// 	reset = false;
// 	distance = '50px';
// 	origin = 'bottom';
// }

$(window).on('load', () => {
	revealSections();
	revealConnectors();
	createBubbles(parentEl, siteContainer);
	createMenuBubble(menuParentEl, menuContainer);
	createButtonUp(menuParentEl, menuContainer);
});

function revealSections() {
	const pageSections = document.querySelectorAll('.section');
	Array.from(pageSections).forEach((item) => {
		ScrollReveal().reveal(item, {
			// mobile: false,
			delay,
			// useDelay: 'once',
			reset,
			origin,
			distance,
			interval: 100,
			beforeReveal: beforeRevealSections,
			afterReveal: afterRevealSections,
		});
	});
}

function revealConnectors() {
	const connectors = document.querySelectorAll('.connector');
	Array.from(connectors).forEach((item) => {
		ScrollReveal().reveal(item, {
			// mobile: false,
			delay: 500,
			afterReveal: connectorCallback,
		});
	});
}

function beforeRevealSections(el) {
	// console.log('element', el.getAttribute('data-sr-id'));
	const bubble1 = document.getElementById('bubble1');
	const bubble2 = document.getElementById('bubble2');
	const bubble3 = document.getElementById('bubble3');
	const bubble4 = document.getElementById('bubble4');
	// const bubble5 = document.getElementById('bubble5');
	const bubble6 = document.getElementById('bubble6');
	const bubble7 = document.getElementById('bubble7');
	const bubble8 = document.getElementById('bubble8');

	if (el.getAttribute('data-sr-id') === '4') {
		bubble1.classList.add('move-up');
		setTimeout(() => {
			bubble1.classList.remove('move-up');
		}, 4500);
	}
	if (el.getAttribute('data-sr-id') === '6') {
		bubble2.classList.add('move-up');
		setTimeout(() => {
			bubble2.classList.remove('move-up');
			bubble3.classList.add('move-up');
		}, 4500);
		setTimeout(() => {
			bubble3.classList.remove('move-up');
		}, 18500);
	}
	if (el.getAttribute('data-sr-id') === '8') {
		bubble4.classList.add('move-up');
		setTimeout(() => {
			bubble4.classList.remove('move-up');
		}, 4500);
	}
	if (el.getAttribute('data-sr-id') === '10') {
		bubble6.classList.add('move-up');
		setTimeout(() => {
			bubble6.classList.remove('move-up');
		}, 4500);
	}
	if (el.getAttribute('data-sr-id') === '12') {
		bubble7.classList.add('move-up');
		setTimeout(() => {
			bubble7.classList.remove('move-up');
		}, 4500);
	}
	if (el.getAttribute('data-sr-id') === '14') {
		bubble8.classList.add('move-up');
		setTimeout(() => {
			bubble8.classList.remove('move-up');
		}, 4500);
	}
}

ScrollReveal().reveal('.company', {
	// mobile: false,
	delay: '500',
	opacity: 1,
	beforeReveal(el) {
		if (el.getAttribute('data-sr-id') === '32') {
			const bubble5 = document.getElementById('bubble5');
			bubble5.classList.add('move-up');
			setTimeout(() => {
				bubble5.classList.remove('move-up');
			}, 4500);
		}
	},
});

ScrollReveal().reveal('.location-map', {
	// mobile: false,
	opacity: 1,
	afterReveal() {
		const bubble10 = document.getElementById('bubble10');
		bubble10.classList.add('move-up');
		setTimeout(() => {
			bubble10.classList.remove('move-up');
		}, 4500);
	},
});

ScrollReveal().reveal('.site-footer', {
	// mobile: false,
	delay: 1000,
	opacity: 1,
	afterReveal() {
		const bubble9 = document.getElementById('bubble9');
		const bubble11 = document.getElementById('bubble11');
		const bubble12 = document.getElementById('bubble12');
		bubble9.classList.add('move-up');
		setTimeout(() => {
			bubble9.classList.remove('move-up');
			bubble11.classList.add('move-up');
		}, 4500);
		setTimeout(() => {
			bubble11.classList.remove('move-up');
			bubble12.classList.add('move-up');
		}, 4600);
		setTimeout(() => {
			bubble12.classList.remove('move-up');
		}, 9000);

		const menuBubble = document.querySelector('[rel="menu-bubble"]');
		const buttonTop = document.querySelector('[rel="top-up"]');
		$(menuBubble).animate({ top: -20 }, 1000);
		$(buttonTop).animate({ opacity: 1 }, 1000);
		$(buttonTop).on('click', () => {
			$('html, body').animate({ scrollTop: 0 }, '500');
			$(buttonTop).animate({ opacity: 0 }, 800);
		});
	},
});

function afterRevealSections(el) {
	if (el.id) {
		const menuItem = document.querySelector(`a[href*=${el.id}]`);
		// console.log('menuItems.pop()', menuItems.pop());
		if (menuItem === null) return false;
		const menuBubble = document.querySelector('[rel="menu-bubble"]');
		const rect = menuItem.getBoundingClientRect();
		const positionTop = rect.top;
		$(menuBubble).animate({ top: positionTop - 20 }, 1000);
	}
}

function connectorCallback(el) {
	const elEnd = $(el).find('.end');
	$(el).animate({ height: '100%' }, 1000, function() {
		elEnd.fadeTo(2, 1);
	});
}
