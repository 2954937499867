const linkScroll = (($) => {
	const init = () => {
		$('a[href^="#"]').click(function(event) {
			const id = $(this).attr('href'),
				offset = $('.site-header').outerHeight() - 48,
				target = $(id).offset().top - offset;

			$('html, body').animate({ scrollTop: target }, 500);
			event.preventDefault();
		});
	};
	return {
		init,
	};
})(jQuery);

export default linkScroll;
