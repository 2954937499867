/* eslint-disable @wordpress/dependency-group */
const menuCoronaWindow = document.querySelector('[rel="menu-corona"]'),
	coronaMenuItem = document.querySelector('[rel="corona"]'),
	body = document.getElementsByTagName('body')[0];

const mediaQuery = window.matchMedia('(min-width: 740px)');

coronaMenuItem.addEventListener('click', (event) => {
	const coronaMenuItemViewportOffset = coronaMenuItem.getBoundingClientRect();
	menuCoronaWindow.style.top = mediaQuery.matches
		? `${coronaMenuItemViewportOffset.top -
				menuCoronaWindow.offsetHeight / 2 +
				(47 - coronaMenuItem.offsetHeight)}px`
		: 0;
	menuCoronaWindow.classList.toggle('modal-corona-view');
	body.classList.add('modal');
});

body.addEventListener('click', function(event) {
	if (
		event.target.classList.contains('menu-item-corona') ||
		event.target.href === 'javascript:void(0)'
	) {
		return false;
	}
	menuCoronaWindow.classList.remove('modal-corona-view');
});
