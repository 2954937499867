const body = document.getElementsByTagName('body')[0];
const buttons = document.querySelectorAll('#menu-footer-menu .menu-item');

buttons.forEach((button) => {
	// const buttonLink = button.querySelector('a');
	// const pageID = buttonLink.getAttribute('rel');
	button.addEventListener('click', (event) => {
		event.preventDefault();
		const pageID = event.target.getAttribute('rel');
		const endpoint = document.querySelector(
			'link[rel="https://api.w.org/"]'
		).href;
		const popupHeaders = new Headers();
		popupHeaders.append('pragma', 'no-cache');
		popupHeaders.append('cache-control', 'no-cache');

		const fetchInit = {
			method: 'GET',
			headers: popupHeaders,
		};

		fetch(`${endpoint}wp/v2/pages/${pageID}`, fetchInit)
			.then((response) => {
				return response.json();
			})
			.then((postResponse) => {
				// console.log('myJson', postResponse);
				const renderedContent = postResponse.content.rendered,
					popupTemplate = wp.template('page-overlay-template');
				// console.log('renderedContent', renderedContent);
				body.insertAdjacentHTML(
					'beforeend',
					popupTemplate({
						content: renderedContent,
					})
				);

				// closePopup();
			})
			.then(() => {
				const closeButtons = document.querySelectorAll(
					'[rel="close-overlay"]'
				);
				const popupContainer = document.querySelector(
					'[rel="page-overlay"]'
				);
				// popupContainer.style.translateX = '100vw';
				// console.log('popupContainer', popupContainer);

				const openTiming = setTimeout(() => {
					body.classList.add('overlay-visible');
					popupContainer.classList.add('page-enter');
				}, 50);

				closeButtons.forEach((closeButton) => {
					closeButton.addEventListener('click', () => {
						body.classList.remove('overlay-visible');
						popupContainer.classList.remove('page-enter');

						clearTimeout(openTiming);
						setTimeout(() => {
							popupContainer.remove();
						}, 800);
					});
				});
			})
			.catch((err) => {
				console.log("There's been an error getting the data", err);
			});
	});
});
