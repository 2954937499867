/* eslint-disable @wordpress/dependency-group */
import $ from 'jquery';
import './inc/in-view';
import './inc/responsive-menus';
import './inc/corona';
import './inc/overlay-page/open-overlay-page';
import linkScroll from './inc/smooth-scroll';
import stickyHeader from './inc/sticky-header';

setTimeout(linkScroll.init, 320);
$(window).on('load', () => {
	stickyHeader.init();
});
