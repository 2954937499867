/**
 * Sticky Header.
 *
 * @package Cloudweb;
 * @author  Valentin Zmaranda
 * @license GPL-2.0+
 */

const stickyHeader = ((window, $) => {
	// eslint-disable-next-line @wordpress/no-unused-vars-before-return,no-unused-vars
	const siteHeader = $('.site-header'),
		siteInner = $('.site-inner');

	let initializer = false;

	const fixedWithPrimaryNav = () => {
		const distanceFromTop = siteInner.offset().top;

		$(window).on('scroll', function() {
			// const sticky = $('header.site-header'),
			scroll = $(window).scrollTop();

			if (scroll >= distanceFromTop + 47) {
				if (initializer === false) {
					siteHeader.addClass('small-logo');
					initializer = true;
				}
			} else if (scroll < distanceFromTop + 47) {
				siteHeader.removeClass('small-logo');
				initializer = false;
			}
		});
	};

	const _moveContentBelowFixedHeader = () => {
		fixedWithPrimaryNav();
	};
	const init = () => {
		_moveContentBelowFixedHeader();
		$(window).resize(() => {
			initializer = false;
			_moveContentBelowFixedHeader();
		});
	};

	return {
		init,
	};
})(window, jQuery);

export default stickyHeader;
